import type { LocationQueryValue } from 'vue-router'
import { format, formatDistanceToNow, parseISO } from 'date-fns'
import { es } from 'date-fns/locale';
import parsePhoneNumber from 'libphonenumber-js'
import { format as formatNumerable } from 'numerable'

export function formatDate(value?: string|Date|null, f?: string): string {
  if (!value) return ''
  if (!f) f = 'PPpp'

  const dt = typeof(value) === 'string' ? parseISO(value) : value
  return format(dt, f, {locale: es})
}

export function formatDateAgo(value?: string | Date): string {
  if (!value) return ''

  const dt = typeof(value) === 'string' ? parseISO(value) : value
  return formatDistanceToNow(dt, {locale: es})
}

export function formatNumber(value: string | number, f?: string, options?: any): string {
  if (!value) value = 0
  if (!f) f = '0,0'
  return formatNumerable(value, f, options)
}

export function formatPhoneNumber(value?: string | null): string {
  if (!value) return ''
  const phoneNumber = parsePhoneNumber(value)
  if (!phoneNumber) return value
  return phoneNumber.formatNational()
}

export function parseStringISO(value?: string | null): Date | null {
  if (!value) return null
  return parseISO(value)
}

export function toNumber<TResult = number> (value?: LocationQueryValue | LocationQueryValue[] | string | number, defaultValue?: number): TResult {
  defaultValue = defaultValue || 0
  if (!value) return defaultValue as TResult
  value = Array.isArray(value) ? value[0] : value
  const number = Number(value)
  return isNaN(number) ? defaultValue as TResult : number as TResult
}
